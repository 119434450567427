  import * as yup from "yup";
  import { urlRegExp } from "../Footer/footer.schema";

  export const membersSchema = yup.object().shape({
    memberName: yup.string().required("Name is required").trim(),
    memberAddress: yup.object()
  .required("Location is required")
  .test('notEmpty', 'Location is required', value => {
    return Object.keys(value).length > 0;
  }),


    memberOwnedZipCode: yup.array().of(
      yup.object().shape({
        tag: yup.string().required('Zip code is required'),
      })
    ),

    memberPhoneNumber:  yup
    .string()
    .transform((value) => {
      return value.replace(/\D/g, "");
    })
    .required("Phone number is required")
    .matches(
      new RegExp("[0-9]{10}"),
      "Phone number is invalid"
    ),

    memberWebsite: yup.string().trim().nullable().test('isValidUrl', 'Invalid URL', function(value) {
      if (value && !urlRegExp.test(value)) {
        throw new yup.ValidationError('Invalid URL', null, 'memberWebsite');
      }
      return true;
    }),
    memberYearsInBusiness: yup.lazy((value) =>
    value === ""
      ? yup.string()
      : yup
          .number()
          
          .typeError("Years in business must be a number")
          .min(0, "Years in business can't be less than 0")
          .max(2147483647, "Years in business is out of range")
  ),
    memberTierType:yup.object().required("Tier type is required"),
    memberCategoriesArray: yup.array().of(
      yup.object().shape({
        memberCategory: yup.array().min(1,"Atleast One Category is required"),
        memberOwnedZipCode: yup.array().of(
          yup.object().shape({
            tag: yup.string(),
          })
        ),
      })
    ),

    
    
    memberLicense: yup.mixed().nullable(),
    memberImages: yup.mixed().nullable(),
    memberProfileImage:yup.mixed().nullable(),
    memberDescription:yup.string().trim().nullable(),
    

    isOwnedZipcodeSelected:yup.boolean().default(false),


  
    
    });

