import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import Input from "../../atoms/Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../../atoms/Button/Button";
import MultipleFilesInput from "../../atoms/MultipleFilesInput/MultipleFilesInput";
import TagInput from "../../atoms/TagInput/TagInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { membersSchema } from "../../pages/Members/members.schema";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCategoryListData } from "../../services/categories.service";
import { StorageEnums } from "../../enums/storageEnums";
import { CustomError } from "../../interfaces/categories.interface";
import { getSignedUrl, uploadImage } from "../../constants/commonFunctions";
import TrashIcon from "../../assets/images/trash-white.svg";
import {
  addMember,
  memberIdData,
  updateMember,
} from "../../services/member.service";
import FileInput from "../../atoms/FileInput/FileInput";
import style from "./AddNewMember.module.scss";
import MaskedInput from "../../atoms/MaskInput/MaskInput";
import { toast } from "react-toastify";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";
import { formatPhoneNumber } from "../../constants/constants";
import { ApiService } from "../../services/apiServices";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import TagInputZip from "../../atoms/TagInputZip/TagInputZip";
import SearchLocation from "../SearchLocation/SearchLocation";
import CategoryDropdown from "../Dropdown/CategoryDropdown";

interface Categories {
  value: string;
  label: string;
}

const TierDropDownList = [
  {
    label: "Tier 1",
    value: 0,
  },
  {
    label: "Tier 2",
    value: 1,
  },
  {
    label: "Unpaid",
    value: 2,
  },
];
const AddNewMember = () => {
  const [ownedZipcode, setOwnedZipCode] = useState<
    { tag: string; dropdownValue: string }[]
  >([]);
  const [licenses, setLicenses] = useState<
    { tag: string; dropdownValue: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForLocation, setLoadingForlocation] = useState<boolean>(false);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );
  const apiService = new ApiService();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const loc = pathname.split("/")[3];
  const id = pathname.split("/")[4];
  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL || "";
  const [geoOptions, setGeoOptions] = useState<{
    description: string;
    zipCode: string;
    latitude: number;
    longitude: number;
  }>();

  const [categories, setCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState<Categories[]>([]);
  const [ownedZipcodes, setOwnedZipcodes] = useState<string[]>([]);
  const [zipDataForValidation, setZipDataForValidation] = useState<{
    [key: string]: string[];
  }>();
  const [checkOwnedPinCode, setCheckOwnedPinCode] = useState<any>([]);
  const [addressFromDB, setAddressFromDB] = useState<any>({});
  const [makeAddressValueNull, setMakeAddressValueNull] =
    useState<boolean>(false);
  const methods = useForm({
    resolver: yupResolver(membersSchema),
  });
  const [editMemberAddress, setEditMemberAddress] = useState<any>({});
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
    watch,
    formState,
  } = methods;
  const { append, fields, remove } = useFieldArray({
    control,
    name: "memberCategoriesArray",
  });

  useEffect(() => {
    // Listen for changes in any form field
    setIsFormEdited(formState.isDirty);
  }, [formState]);

  useEffect(() => {
    const watchedValue = methods.watch("memberAddress");
    if (
      watchedValue &&
      typeof watchedValue === "object" &&
      "label" in watchedValue
    ) {
      getGeoData(watchedValue.label);
    }
  }, [methods.watch("memberAddress")]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (id) getMemberIdData();
  }, [id]);

  useEffect(() => {
    setCategoriesOptions(
      (categories as any).filter(
        (item1: any) =>
          !(watch("memberCategoriesArray") as any)?.some(
            (item2: any) =>
              item1.value === item2.value && item1.label === item2.label
          )
      )
    );
  }, [JSON.stringify(watch("memberCategoriesArray")), categories]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current && fields?.length === 0) {
      append({ memberCategory: [] });
      isFirstRun.current = false;
    }
    if (getValues("isOwnedZipcodeSelected") && fields?.length === 0) {
      append({ memberCategory: [] });
    }
  }, [fields, getValues("isOwnedZipcodeSelected")]);

  useEffect(() => {
    // Listen for changes in memberCategoriesArray
    getValues("memberCategoriesArray")?.forEach((item, index) => {
      if (
        watch(`memberCategoriesArray.${index}`) &&
        !getValues(`memberCategoriesArray.${index}.memberOwnedZipCode`)?.length
      ) {
        // Reset the respective memberOwnedZipCode values to empty array
        setValue(`memberCategoriesArray.${index}.memberOwnedZipCode`, []);
      }
    });
  }, [JSON.stringify(watch("memberCategoriesArray"))]);

  async function getGeoData(location: any) {
    setLoadingForlocation(true);
    try {
      let payload = {
        url: `geo/complete-address?search=${location}`,
        headerToken: localStorageUser?.data.accessToken,
      };
      let resp = await apiService.get(payload);

      if (!resp?.data?.completeAddress?.zipCode) {
        setMakeAddressValueNull(true);
        toast.error("Please select other address");
        methods.setValue("memberAddress", {});
        methods.setError("memberAddress", { message: "Location is required" });
        // methods.trigger("memberAddress");
        return;
      } else {
        setMakeAddressValueNull(false);
        methods.trigger("memberAddress");
      }
      setGeoOptions(resp.data.completeAddress);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingForlocation(false);
    }
  }

  const onSubmit = async (data: FieldValues) => {
    if (data.isOwnedZipcodeSelected) {
      const result = findMatchingValues(
        data.memberCategoriesArray,
        zipDataForValidation
      );

      const duplicateErrors = checkDuplicateZipCodes(result);

      if (duplicateErrors) {
        duplicateErrors.forEach((error: any) => {
          toast.error(
            `Error: The zip codes ${error.duplicateZipCodes.join(
              ", "
            )} are already associated with the Catgory ${error.label}.`
          );
        });
        return;
      }
    }
    if (loc === "edit") {
      // uploading profile Image
      let ProfileImageKey = "";
      let memberImagesToUpdate: string[] = [];
      try {
        setLoading(true);
        if (typeof data.memberProfileImage !== "string") {
          const profileImageInfo = {
            fileName: data.memberProfileImage.name,
            contentType: data.memberProfileImage.type,
          };
          let profileResponse = await getSignedUrl(profileImageInfo);
          ProfileImageKey = CloudFrontURL + profileResponse?.data.key;
          await uploadImage(
            data.memberProfileImage,
            profileResponse.data.uploadUrl
          );
        } else {
          ProfileImageKey = data.memberProfileImage;
        }

        let memberImagesToUpload = data.memberImages.filter((item: any) => {
          return typeof item !== "string";
        });
        data.memberImages.map((item: any) => {
          if (typeof item == "string") {
            memberImagesToUpdate.push(item);
          }
        });
        if (memberImagesToUpload?.length !== 0) {
          const signedUrls = await Promise.all(
            memberImagesToUpload.map(
              async (imageObject: File, index: number) => {
                const { lastModified } = imageObject;
                const imageInfo = {
                  fileName: imageObject.name,
                  contentType: imageObject.type,
                };
                const res = await getSignedUrl(imageInfo);
                return {
                  uploadUrl: res.data.uploadUrl,
                  key: res.data.key,
                  stamp: `${lastModified}_${index}`,
                };
              }
            )
          );

          await Promise.all(
            memberImagesToUpload.map(
              async (imageObject: any, index: number) => {
                const signedUrlInfo = signedUrls.find(
                  (urlInfo) =>
                    urlInfo.stamp == `${imageObject.lastModified}_${index}`
                );
                if (signedUrlInfo) {
                  await uploadImage(imageObject, signedUrlInfo.uploadUrl);
                } else {
                  console.error(`Signed URL not found for ${imageObject.name}`);
                }
              }
            )
          );
          memberImagesToUpdate = [
            ...memberImagesToUpdate,
            ...signedUrls.map((item: any) => CloudFrontURL + item.key),
          ];
        }
        const categoryZipData = data.memberCategoriesArray.map((item: any) => {
          return {
            categoryId: item.value,
            zipCode: item.memberOwnedZipCode
              ? item.memberOwnedZipCode.map((zip: any) => zip.tag)
              : [],
          };
        });

        const payload = {
          name: data.memberName,
          address: geoOptions?.description,
          profile: ProfileImageKey || "",
          zip: geoOptions?.zipCode,
          phone: data.memberPhoneNumber,
          website: data.memberWebsite || "",
          description: data.memberDescription || "",
          businessYear: data.memberYearsInBusiness.toString() || "",
          tier: data.memberTierType.label,
          latitude: geoOptions?.latitude,
          longitude: geoOptions?.longitude,
          license: data.memberLicense.map((item: any) => item.tag),
          gallery: memberImagesToUpdate,
          categoryZip: categoryZipData
        };

        await updateMember(id, payload, localStorageUser);
        toast.success("Member updated successfully");
        navigate("/dashboard/members");
      } catch (e: any) {
        if (e?.response?.data?.message) toast.error(e.response.data.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        let signedUrls: any;
        if (data.memberImages) {
          signedUrls = await Promise.all(
            data?.memberImages?.map(
              async (imageObject: File, index: number) => {
                const { lastModified } = imageObject;
                const imageInfo = {
                  fileName: imageObject.name,
                  contentType: imageObject.type,
                };
                const res = await getSignedUrl(imageInfo);
                return {
                  uploadUrl: res.data.uploadUrl,
                  key: res.data.key,
                  stamp: `${lastModified}_${index}`,
                };
              }
            )
          );
          await Promise.all(
            data.memberImages?.map(async (imageObject: any, index: number) => {
              const signedUrlInfo = signedUrls?.find(
                (urlInfo: any) =>
                  urlInfo.stamp == `${imageObject.lastModified}_${index}`
              );

              if (signedUrlInfo) {
                await uploadImage(imageObject, signedUrlInfo.uploadUrl);
              } else {
                console.error(`Signed URL not found for ${imageObject.name}`);
              }
            })
          );
        }
        let profileRes;
        if (data.memberProfileImage) {
          const profileImageInfo = {
            fileName: data.memberProfileImage?.name,
            contentType: data.memberProfileImage?.type,
          };
          profileRes = await getSignedUrl(profileImageInfo);
          await uploadImage(data.memberProfileImage, profileRes.data.uploadUrl);
        }

        const categoryZipData = data.memberCategoriesArray.map((item: any) => {
          return {
            categoryId: item.value,
            zipCode: item.memberOwnedZipCode
              ? item.memberOwnedZipCode.map((zip: any) => zip.tag)
              : [],
          };
        });

        const payload = {
          name: data.memberName,
          address: geoOptions?.description,
          profile: profileRes?.data.key
            ? CloudFrontURL + profileRes?.data.key
            : "",
          zip: geoOptions?.zipCode,
          phone: data.memberPhoneNumber,
          website: data.memberWebsite,
          description: data.memberDescription,
          businessYear: data.memberYearsInBusiness.toString(),
          tier: data.memberTierType.label,
          // isOwnZip: true,

          latitude: geoOptions?.latitude,
          longitude: geoOptions?.longitude,
          license: licenses.map((item: any) => item.tag),
          gallery: signedUrls
            ? signedUrls?.map((item: any) => CloudFrontURL + item.key)
            : [],
          categoryZip: categoryZipData
            
        };

        await addMember(payload, localStorageUser);
        toast.success("Member added successfully");
        navigate("/dashboard/members");
      } catch (e: any) {
        if (e?.response?.data?.message) toast.error(e.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  };

  function findMatchingValues(dataArray: any, otherObject: any) {
    const matchingValues = [];

    for (let item of dataArray) {
      const label = item.label;
      const memberOwnedZipCodes = item.memberOwnedZipCode
        ? item.memberOwnedZipCode.map((zip: any) => zip.tag)
        : [];

      let filteredDataForUpdate = memberOwnedZipCodes?.filter(
        (el: any) =>
          !checkOwnedPinCode.some((pinCode: any) => pinCode.ownZip.includes(el))
      );
      const data = loc == "edit" ? filteredDataForUpdate : memberOwnedZipCodes;

      const matchingZipCodes = data.filter(
        (zip: any) => otherObject[label] && otherObject[label].includes(zip)
      );

      if (matchingZipCodes.length > 0) {
        matchingValues.push({
          label: label,
          value: item.value,
          zipCodes: matchingZipCodes,
        });
      }
    }

    return matchingValues;
  }
  useEffect(() => {
    getZipData();
  }, []);

  async function getZipData() {
    let payload = {
      url: `categories/zip-code`,
    };
    let data = await apiService.get(payload);
    const result: { [key: string]: string[] } = {};

    data.data.result.forEach((item: any) => {
      result[item.name] = item.ownZip;
    });

    setZipDataForValidation(result);
  }

  const addCategoryField = () => {
    if (fields.length < categories?.length) {
      append({
        memberCategory: [],
        memberOwnedZipCode: [],
      });
      setOwnedZipcodes([...ownedZipcodes, ""]); // Add a new zip code
    } else {
      toast.error(`You can add up to ${categories?.length} categories`);
    }
  };
  const handleRemove = (index: number) => {
    remove(index);
    const updatedZipcodes = [...ownedZipcodes];
    updatedZipcodes.splice(index, 1);
    setOwnedZipcodes(updatedZipcodes);
  };

  const getCategories = async () => {
    try {
      const response = await getCategoryListData(localStorageUser);
      response?.data?.result.map((item: any) => {
        if (item["icon"]) {
          item["icon"] = (
            <img
              src={`${process.env.REACT_APP_CLOUDFRONT_URL}${item["icon"]}`}
              alt="Icon"
              width="70px"
              height="70px"
            />
          );
        }
        return item;
      });

      const labelValueArray = response?.data?.result.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setCategories(labelValueArray);
      // setTableData(response?.data?.result);
    } catch (error) {
      console.error(
        "error in getting categories table data ",
        (error as CustomError)?.response?.data?.message
      );
    }
  };

  const getMemberIdData = async () => {
    try {
      setLoading(true);
      const response = await memberIdData(id, localStorageUser);

      let data = response.data;
      setAddressFromDB({ label: data.address, value: data.address });
      if (response?.data) {
        setEditMemberAddress({ label: data.address, value: data.address });
        const resetObject = {
          memberName: data.name,
          memberAddress: { label: data.address, value: data.address },
          memberPhoneNumber: formatPhoneNumber(data.phone),
          memberWebsite: data.website || "",
          memberDescription: data.description || "",
          memberYearsInBusiness: data.businessYear || "",
          memberTierType: {
            label: data.tier,
            value: TierDropDownList.find((el) => el.label == data.tier)?.value,
          },
          memberCategory: data.categories.map((item: any) => ({
            label: item.name,
            value: item.id,
          })),
          memberLicense: data.license.map((item: any) => {
            return {
              tag: item,
            };
          }),
          memberProfileImage: data.profile,
          memberImages: data.gallery,
          memberCategoriesArray: data?.categories.map((item: any) => ({
                label: item.name,
                value: item.id,
                memberOwnedZipCode: item.ownZip.map((zip: any) => ({
                  tag: zip,
                })),
              }))
            ,
        };
        methods.reset(resetObject);
        setCheckOwnedPinCode(response.data.categories);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.statusCode == 400 ||
        error?.response?.data?.message == "Not found"
      ) {
        navigate("/dashboard/members");
        toast.error("No member found");
      }
      console.error("error in fetching category of id", error);
    } finally {
      setLoading(false);
    }
  };

  function checkDuplicateZipCodes(dataArray: any) {
    const errors: any = [];
    dataArray.map((item: any) => {
      errors.push({
        duplicateZipCodes: item.zipCodes,
        label: item.label,
      });
    });
    return errors.length > 0 ? errors : null;
  }

  return (
    <>
      <FormProvider {...methods}>
        <div className={`${style.member}`}>
          <div
            className={`flex-wrap align-center justify-between ${style.member__header}`}
          >
            <h2>{loc === "edit" ? "Edit Member" : "Add New Member"}</h2>

            <div className={`flex ${style.member__actions}`}>
              <Link to="/dashboard/members">
                <Button
                  disabled={loading}
                  // loading={loading}
                  buttonClass="outline md"
                  label="Back"
                />
              </Link>
              <Button
                disabled={loadingForLocation || loading}
                loading={loading}
                buttonClass="md"
                label="Save"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>

        <div className={`flex-column gap-20 ${style.member__form} `}>
          <div className={`${style.member__row} `}>
            <div className={`${style.member__col} `}>
              {" "}
              <Input
                type="text"
                placeholder="Name"
                label="Name*"
                register={register}
                registerName="memberName"
                errorMessage={errors?.memberName?.message}
              />
            </div>
            <div className={`${style.member__col} `}>
              <SearchLocation
                name="memberAddress"
                label="Location*"
                placeHolder="Location"
                control={control}
                error={errors?.memberAddress?.message}
                isDisable={false}
                defaultValue={editMemberAddress?.label}
              />
            </div>
          </div>
          <div className={`${style.member__row} `}>
            <div className={`${style.member__col} `}>
              <MaskedInput
                placeholder="Phone Number"
                label="Phone Number*"
                name="memberPhoneNumber"
                register={register}
                errors={errors}
                maskChar={""}
                mask={"(999) 999-9999"}
              ></MaskedInput>
            </div>
            <div className={`${style.member__col} `}>
              <Input
                type="text"
                placeholder="Website"
                label="Website"
                register={register}
                registerName="memberWebsite"
                errorMessage={errors?.memberWebsite?.message}
              />
            </div>
          </div>
          <div className={`${style.member__row} `}>
            <div className={`${style.member__col} `}>
              <Input
                type="number"
                placeholder="Years In Business"
                label="Years In Business"
                register={register}
                registerName="memberYearsInBusiness"
                errorMessage={errors?.memberYearsInBusiness?.message}
              />
            </div>
          </div>
        </div>
        <div className={` ${style.member__form} `}>
          <h2>Description</h2>
          <Input
            type="textarea"
            placeholder="Description"
            register={register}
            registerName="memberDescription"
            errorMessage={errors?.memberDescription?.message}
          />
        </div>
        <div className={` ${style.member__form} `}>
          <h2>Profile Image</h2>
          <FileInput
            register={register}
            registerName="memberProfileImage"
            disabled={false}
            accept="image/png,image/jpeg,image/jpg,image/svg+xml"
            control={control}
            errorMessage={errors?.memberProfileImage?.message}
            value={getValues("memberProfileImage") ?? ""}
          />
        </div>

        <div className={`flex-column gap-20 ${style.member__form} `}>
          <div className={`${style.member__row} `}>
            <div className={`${style.member__col} `}>
              <Dropdown
                name="memberTierType"
                label="Status*"
                error={errors?.memberTierType?.message?.toString()}
                options={TierDropDownList}
                placeHolder="Status"
              />
            </div>
            <div className={`${style.member__col} `}>

           
            <TagInput
            label="License"
            control={control}
            name="memberLicense"
            onTagsChange={setLicenses}
            placeholder="Add License"
            error={errors?.memberLicense?.message?.toString()}
          />
 </div>
          </div>
         
        </div>
        <div className={` ${style.member__form} `}>
            <div
              className={`flex-wrap justify-between align-center ${style.member__formHeader} `}
            >
              <h2>Selected Categories</h2>
              <Button buttonClass="md" onClick={addCategoryField}>
                + Add
              </Button>
            </div>

            {fields.map((item, index) => (
              <div
                className={`${style.member__addField} ${
                  fields.length > 1 && style.member__addFieldIcon
                }`}
                key={item.id}
              >
                <div className={`${style.member__row} `}>
                  <div className={`${style.member__col} `}>
                    <CategoryDropdown
                      name={`memberCategoriesArray.${index}`}
                      label={`Category*`}
                      error={errors?.memberCategoriesArray?.[
                        index
                      ]?.memberCategory?.message?.toString()}
                      options={categoriesOptions}
                      placeHolder="Category"
                    />
                  </div>
                  <div className={`${style.member__col} `}>
                    <TagInputZip
                      label="Owned Zipcode"
                      control={control}
                      name={`memberCategoriesArray.${index}.memberOwnedZipCode`}
                      onTagsChange={setOwnedZipCode}
                      placeholder="Owned Zipcode"
                      error={errors?.memberOwnedZipCode?.message?.toString()}
                    />
                  </div>
                </div>

                {fields.length > 1 && (
                  <div className={`${style.member__buttons} `}>
                    <span role="button" onClick={() => handleRemove(index)}>
                      <img src={TrashIcon} alt="delete" />
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        <div className={` ${style.member__form} `}>
          <h2>Gallery</h2>
          <MultipleFilesInput
            control={control}
            registerName="memberImages"
            register={register}
            errorMessage={errors?.memberImages?.message}
          />
        </div>
      </FormProvider>
      {loading && <GlobalLoader />}
    </>
  );
};

export default AddNewMember;
